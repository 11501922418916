export let sanitizeNext = (next?: string, def: string | null = null) => {
  if (!next) return def;
  let url = new URL(next, def || 'https://weekrise.com');

  if (process.env.NODE_ENV == 'development' && url.hostname == 'localhost') return next;
  if (url.hostname == 'weekrise.com' || url.hostname.endsWith('.weekrise.com')) return next;

  return def;
};

export let checkNext = (next: string) => !!sanitizeNext(next);

export let getUrlWithParams = (url: string, data: { [key: string]: string }) => {
  let u = new URL(url, 'https://weekrise.com');

  for (let key in data) {
    let value = key == 'next' ? sanitizeNext(data[key]) : data[key];
    if (!value) continue;
    u.searchParams.set(key, value);
  }

  return u.pathname + u.search;
};
