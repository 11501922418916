import { Center, Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import React from 'react';

let Wrapper = styled('div')`
  height: 100%;

  @media screen and (max-width: 900px) {
    background: white !important;
  }
`;

let Inner = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;

  @media screen and (max-width: 1100px) {
    gap: 50px;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    padding: 0px;
  }
`;

let Content = styled('main')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

let ContentInner = styled('div')`
  background: white;
  height: 100%;
`;

let SideContent = styled('aside')`
  height: 100%;
  color: white;
  background: url(/auth/bg.svg);
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

let WeekriseSidecontent = ({
  title,
  body
}: {
  title?: React.ReactNode;
  body?: React.ReactNode;
}) => (
  <SideContent>
    <Center>
      <div style={{ maxWidth: 600, padding: 15 }}>
        <img
          style={{ maxWidth: 450, margin: '0px auto 40px auto', display: 'flex' }}
          src="/auth/preview.webp"
          alt="An image showing a task in weekrise."
        />

        <div style={{ textAlign: 'center' }}>
          <Text as="h1" size={60} color="inherit" weight={800}>
            {title || 'Welcome to Weekrise'}
          </Text>

          <Spacer height={10} />

          <Text as="h2" size={26} color="inherit" weight={600}>
            {body || 'The easiest way to manage work and life.'}
          </Text>
        </div>
      </div>
    </Center>
  </SideContent>
);

export let SetupLayout = ({
  children,
  title,
  body
}: {
  children: React.ReactNode;
  title?: React.ReactNode;
  body?: React.ReactNode;
}) => {
  return (
    <Wrapper>
      <Inner>
        <Content>
          <ContentInner>{children}</ContentInner>
        </Content>

        <WeekriseSidecontent title={title} body={body} />
      </Inner>
    </Wrapper>
  );
};
