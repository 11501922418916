import { keyframes, styled } from 'buttered';

let spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

let Wrapper = styled('div')`
  width: 60px;
  height: 60px;
  margin: 0px auto;
  position: relative;

  .padlock {
    width: 70%;
    height: 70%;
    position: absolute;
    top: 15%;
    left: 15%;
  }

  .spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    animation: ${spin} 1s linear infinite;
  }
`;

export let SecureLoading = () => {
  return (
    <Wrapper>
      <svg
        className="padlock"
        viewBox="0 0 76 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* <circle cx="38" cy="38" r="38" fill="#f78fb3" /> */}
        <path
          d="M50.25 36.25H25.75C23.817 36.25 22.25 37.817 22.25 39.75V52C22.25 53.933 23.817 55.5 25.75 55.5H50.25C52.183 55.5 53.75 53.933 53.75 52V39.75C53.75 37.817 52.183 36.25 50.25 36.25Z"
          stroke="#f78fb3"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.25 36.25V29.25C29.2478 27.0801 30.052 24.9868 31.5064 23.3764C32.9608 21.7661 34.9617 20.7536 37.1206 20.5355C39.2796 20.3174 41.4425 20.9093 43.1896 22.1963C44.9367 23.4833 46.1432 25.3735 46.575 27.5"
          stroke="#f78fb3"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <svg
        className="spinner"
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M95 50C95 25.1472 74.8528 5 50 5C25.1472 5 5 25.1472 5 50"
          stroke="url(#paint0_linear_0_3)"
          stroke-width="8"
          stroke-linecap="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_0_3"
            x1="95"
            y1="50"
            x2="5"
            y2="50"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#f78fb3" />
            <stop offset="1" stop-color="#f78fb3" stop-opacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </Wrapper>
  );
};
