import { WeekriseLogo } from '@highloop-pkg/logo';
import * as Sentry from '@sentry/nextjs';
import useInterval from '@use-it/interval';
import { Spacer, Text, toast } from '@vapor/ui';
import { styled } from 'buttered';
import { useCallback, useEffect } from 'react';
import useWindowFocus from 'use-window-focus';
import { Box, BoxInner } from '../components/box';
import { SecureLoading } from '../components/secureLoading';
import { client } from '../state/sdk/client';

let Wrapper = styled('div')`
  text-align: center;
`;

let Center = styled('div')`
  width: fit-content;
  margin: 0px auto;
`;

let LogoWrapper = styled(Center)`
  margin: 0px auto 40px auto;
`;

export let WaitingScene = ({ email, next, checkToken, csrf }: { email: string; next: string; checkToken: string; csrf: string }) => {
  let focussed = useWindowFocus();

  useEffect(() => {
    if (typeof window == 'undefined') return;
  }, [checkToken, email]);

  let check = useCallback(() => {
    if (!checkToken) return;

    let goToNext = (mustSetup: boolean) => {
      if (
        mustSetup &&
        !next.includes('/app/join?token=') && // No need to create a team if we're invited to one
        !next.includes('/setup')
      ) {
        setTimeout(() => {
          window.location.replace('/setup');
        });
      } else {
        setTimeout(() => {
          window.location.replace(next);
        });
      }
    };

    client
      .post('/auth/check', { token: checkToken, email, csrf })
      .then((res) => {
        if (res.data.verified) {
          goToNext(res.data.mustSetup);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.log('Auth Check Error', err);
        toast.error(`An error occurred. We're retrying...`);
      });
  }, [email, next, checkToken]);

  useEffect(() => {
    if (focussed) check();
  }, [focussed, check]);

  useInterval(() => check(), checkToken && focussed ? 5000 : null);

  return (
    <Box>
      <BoxInner>
        <Wrapper>
          <LogoWrapper>
            <WeekriseLogo size={70} />
          </LogoWrapper>

          <Text as="h1" size={28} weight={600}>
            You've got mail!
          </Text>

          <Spacer height="15px" />

          <Text as="p" size={14} weight={500}>
            Click the link in the email we just sent you to verify your email address.
          </Text>

          <Spacer height="40px" />

          <Center>
            <SecureLoading />
          </Center>
        </Wrapper>
      </BoxInner>
    </Box>
  );
};
