import { WeekriseLogo } from '@highloop-pkg/logo';
import * as Sentry from '@sentry/nextjs';
import { Button, Error, Input, Spacer, Text } from '@vapor/ui';
import { styled } from 'buttered';
import { useFormik } from 'formik';
import Head from 'next/head';
import Link from 'next/link';
import { useState } from 'react';
import { useGoogleLogin } from 'react-google-login';
import * as Yup from 'yup';
import { BoxInner } from '../components/box';
import { HR } from '../components/hr';
import { SetupLayout } from '../components/setupLayout';
import { getUrlWithParams } from '../lib/getUrlWithNext';
import { client } from '../state/sdk/client';
import { WaitingScene } from './waiting';

let Wrapper = styled('div')`
  text-align: center;

  &.weekrise {
    --vapor-primary: #faaca8;
  }
`;

let Center = styled('div')`
  width: fit-content;
  margin: 0px auto;
`;

let LogoWrapper = styled(Center)`
  margin: 0px auto 40px auto;
`;

let LoginSchema = Yup.object().shape({
  email: Yup.string().email(`That email doesn't look valid`).required('Make sure to enter your email')
});

let SignupSchema = Yup.object().shape({
  name: Yup.string().max(100, `Sorry, 100 characters max`).required('Make sure to enter your name'),
  email: Yup.string().email(`That email doesn't look valid`).required('Make sure to enter your email')
});

export let Auth = ({ type, next, csrf }: { type: 'login' | 'signup'; next: string; csrf: string }) => {
  let [error, setError] = useState<string>();
  let [showSpinner, setShowSpinner] = useState(false);
  let [authenticatingWithGoogle, setAuthenticatingWithGoogle] = useState(false);
  let [waitingData, setWaitingData] = useState<{
    email: string;
    next: string;
    checkToken: string;
  }>();

  let { signIn: signInWithGoogle, loaded: googleLoaded } = useGoogleLogin({
    clientId: '702710613853-o1h0fu8su26jvre68vr3q77ph3m13o5h.apps.googleusercontent.com',
    accessType: 'offline',
    responseType: 'code',
    scope: 'profile email openid',
    onSuccess: (event: any) => {
      client
        .post('/auth/google', {
          code: event.code,
          csrf,
          redirectUri: window.location.origin
        })
        .then((res) => {
          if (
            res.data.mustSetup &&
            !next.includes('/app/join?token=') && // No need to create a team if we're invited to one
            !next.includes('/setup')
          ) {
            setTimeout(() => {
              window.location.replace('/setup');
            });
          } else {
            setTimeout(() => {
              window.location.replace(next);
            });
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
          setAuthenticatingWithGoogle(false);
        });
    },
    onFailure: (err) => {
      Sentry.captureException(err);
      console.warn(err);
      setAuthenticatingWithGoogle(false);
    },
    cookiePolicy: 'single_host_origin'
  });

  let doSignupWithEmail = async (email: string, name: string) => {
    try {
      let res = await client.post('/auth/signup', {
        email,
        name,
        csrf
      });

      let checkToken = res.data.checkToken;

      setWaitingData({ email, next, checkToken });
    } catch (error) {
      setShowSpinner(false);

      if (error.response) {
        let code = error.response.data.code;

        if (code == 'user_exists') {
          return setError(`You're already signed up. Log in to continue.`);
        }
      }

      Sentry.captureException(error);

      console.log(error);
      setError('Could not authenticate you');
    }
  };

  let doLoginWithEmail = async (email: string) => {
    try {
      let res = await client.post('/auth/login', {
        email,
        csrf
      });

      let checkToken = res.data.checkToken;

      setWaitingData({ email, next, checkToken });
    } catch (error) {
      setShowSpinner(false);

      if (error.response) {
        let code = error.response.data.code;

        if (code == 'user_not_signed_up') {
          return setError(`You're not signed up yet. Sign up to continue.`);
        }
      }

      Sentry.captureException(error);

      console.log(error);
      setError('Could not authenticate you');
    }
  };

  let formik = useFormik({
    initialValues: {
      name: '',
      email: ''
    },
    validationSchema: type == 'login' ? LoginSchema : SignupSchema,
    onSubmit: (values) => {
      setShowSpinner(true);

      if (type == 'signup') {
        doSignupWithEmail(values.email, values.name);
      } else {
        doLoginWithEmail(values.email);
      }
    }
  });

  if (waitingData) return <WaitingScene {...waitingData} csrf={csrf} next={next} />;

  return (
    <SetupLayout>
      <Head>
        <link rel="prefetch" href="https://apis.google.com/js/api.js" />
      </Head>

      <BoxInner>
        <Wrapper>
          <LogoWrapper>
            <WeekriseLogo size={70} />
          </LogoWrapper>

          <Text as="h1" size={28} weight={600}>
            {type == 'signup' && 'Welcome to Weekrise!'}
            {type == 'login' && 'Welcome back to Weekrise!'}
          </Text>

          <Spacer height="16px" />

          <Text as="p" size={14} weight={500}>
            {type == 'signup' && (
              <span>
                Create an account.{' '}
                <Link replace href={getUrlWithParams('/login', { next })}>
                  <a>Log in instead</a>
                </Link>
              </span>
            )}
            {type == 'login' && (
              <span>
                Log in to your account.{' '}
                <Link replace href={getUrlWithParams('/signup', { next })}>
                  <a>Sign up instead</a>
                </Link>
              </span>
            )}
          </Text>

          <Spacer height="50px" />

          <form onSubmit={formik.handleSubmit} style={{ textAlign: 'left' }}>
            {type == 'signup' && (
              <>
                <Input label="Name" placeholder="What's your name" name="name" value={formik.values.name} error={formik.touched.name && formik.errors.name} onChange={formik.handleChange} />

                <Spacer height="10px" />
              </>
            )}

            <Input label="Email" placeholder="Enter your email" name="email" value={formik.values.email} error={formik.touched.email && formik.errors.email} onChange={formik.handleChange} />

            {error && (
              <>
                <Spacer height="10px" />
                <Error>{error}</Error>
              </>
            )}

            <Spacer height="15px" />

            <Button fullWidth variant="primary" type="submit" loading={showSpinner} disabled={authenticatingWithGoogle}>
              Continue
            </Button>
          </form>

          <Spacer height="10px" />

          <HR />

          <Spacer height="10px" />

          <Button
            icon={
              <svg height={16} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.64 9.20455C17.64 8.56636 17.5827 7.95273 17.4764 7.36364H9V10.845H13.8436C13.635 11.97 13.0009 12.9232 12.0477 13.5614V15.8195H14.9564C16.6582 14.2527 17.64 11.9455 17.64 9.20455Z" fill="#4285F4" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9 18C11.43 18 13.4673 17.1941 14.9564 15.8195L12.0477 13.5614C11.2418 14.1014 10.2109 14.4205 9 14.4205C6.65591 14.4205 4.67182 12.8373 3.96409 10.71H0.957273V13.0418C2.43818 15.9832 5.48182 18 9 18Z" fill="#34A853" />
                <path fillRule="evenodd" clipRule="evenodd" d="M3.96409 10.71C3.78409 10.17 3.68182 9.59318 3.68182 9C3.68182 8.40682 3.78409 7.83 3.96409 7.29V4.95818H0.957273C0.347727 6.17318 0 7.54773 0 9C0 10.4523 0.347727 11.8268 0.957273 13.0418L3.96409 10.71Z" fill="#FBBC05" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9 3.57955C10.3214 3.57955 11.5077 4.03364 12.4405 4.92545L15.0218 2.34409C13.4632 0.891818 11.4259 0 9 0C5.48182 0 2.43818 2.01682 0.957273 4.95818L3.96409 7.29C4.67182 5.16273 6.65591 3.57955 9 3.57955Z" fill="#EA4335" />
              </svg>
            }
            onClick={() => signInWithGoogle()}
            loading={authenticatingWithGoogle}
            fullWidth
            variant="tertiary"
          >
            Sign in with Google
          </Button>

          <Spacer height="10px" />

          <Text as="p" color="var(--vapor-accent-6)">
            By signing up you agree to our{' '}
            <a href="https://weekrise.com/terms" target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://weekrise.com/privacy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            .
          </Text>
        </Wrapper>
      </BoxInner>
    </SetupLayout>
  );
};
